import styles from "./TermsPopup.module.scss";

const PrivacyPopup = ({ data }) => {
  return (
    <div className={styles.popup}>
      <p className={`ffm fs32 fw700 c084571 ${styles.pageTitle}`}>
        GENVITE PRIVACY POLICY
      </p>
      <div className={styles.content}>
        <p
          className={`ffm fw6000 fs18 c3D4B5C`}
          style={{ marginBottom: "50px" }}
        >
          Last Updated: May 2023
        </p>
        <p className={styles.text}>
          Genvite (“Genvite,” “we,” “us,” or “our”) respects your privacy and is
          committed to protecting it through our compliance with this Privacy
          Policy. This policy describes the types of information we may collect
          from you or that you may provide when you visit the Genvite website,
          mobile websites, mobile applications (our “Services”) and our
          practices for collecting, using, maintaining, protecting, and
          disclosing that information, as well as your rights concerning such
          information.
        </p>
        <p className={styles.text}>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is to not
          use our Services. By accessing or using the Services, you agree to
          this Privacy Policy. This policy may change from time to time so
          please check here periodically. Your continued use of the Services
          after we make changes is deemed to be acceptance of those changes.
        </p>

        <p className={styles.title}>
          INFORMATION WE COLLECT AND HOW WE USE IT.
        </p>

        <p className={styles.text}>
          This section describes the types of personal information we may
          collect about you. Such information is generally collected either (i)
          directly from your voluntary engagement with the Services; or (ii)
          automatically from you when you interact with the Services. We may
          also collect information about you from third-parties in order to
          enhance the Services as described below.
        </p>
        <p className={styles.text}>
          The following categories of personal information we may collect from
          you includes:
        </p>

        <ul className={styles.bullets}>
          <li>
            Contact and account information. In order to set up an account and
            provide you with the Services, we may collect your name, phone
            number, and email address.
          </li>
          <li>
            Feedback information. To receive and respond to your customer
            service requests or comments on your experience with Genvite, we may
            collect your name, phone number, address, email address and any
            relevant history.
          </li>
          <li>
            Personalization information. In order to provide you with the
            Services, such as identifying and providing you with suggested
            contacts, we may collect your name, phone number, email address,
            physical address, family members, and their name.
          </li>
          <li>
            Call information. For quality assurance, we may record phone calls
            placed using the Services. We may also collect certain audio
            messages when you volunteer to use our Services to send a
            pre-recorded invitation.
          </li>
          <li>
            Other Usage information. So that we may understand and improve on
            the user experience and functionality of the Services, we may
            collect information about how you interact with the Services such as
            mouse movements, clicks, keystrokes, the date of your visit, the
            time spent on web pages, or other actions.
          </li>
        </ul>

        <p className={styles.text}>
          Personal information that is automatically collected may be obtained
          through cookies and similar technologies. Cookies are small files that
          are stored on your computer or mobile device through a user ID or
          other preferences. We use cookies to personalize your experience when
          you use the Services (e.g. auto-populating your log-in credentials or
          settings). You are free to set your browser or operating system
          settings to limit certain tracking or to decline cookies, but by doing
          so, you may not be able to use certain features through our Services
          or take full advantage of all our Services. For more information about
          this, please see our Cookie Policy section below.
        </p>
        <p className={styles.text}>
          We may collect information about you from third-parties such as
          community shuls and yeshivas, to determine your family network solely
          to enhance the performance of Services, such as providing you with
          relevant contact suggestions to consider for invitation.
        </p>

        <p className={styles.title}>WHAT WE DO WITH YOUR INFORMATION.</p>

        <p className={styles.text}>
          We may use information that we collect about you for the following:
        </p>

        <ul className={styles.bullets}>
          <li>
            To provide you with our services, and any personalized information
            about them, including to share your contact information with other
            users;
          </li>
          <li>
            To personalize your experience with our services, such as, making
            relevant contact suggestions;
          </li>
          <li>
            To maintain your account and provide you with the latest updates to
            our policies, changes to our website, or any services we offer;
          </li>
          <li>
            To receive and respond to your inquiries and customer service
            requests;
          </li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us; and
          </li>
          <li>
            To better understand your experience with and improve our Services.
          </li>
        </ul>

        <p className={styles.title}>DISCLOSURE OF YOUR INFORMATION.</p>

        <p className={styles.ulHeader}>
          We may disclose your personal information as follows:
        </p>

        <ul className={styles.bullets}>
          <li>To other users;</li>
          <li>
            To contractors, service providers, and other third parties we use to
            support and/or improve our Services;
          </li>
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request;
          </li>
          <li>
            To enforce or investigate potential violations of our Terms of Use;
            or
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of Genvite, our customers, or others.
          </li>
        </ul>

        <p className={styles.title}>COOKIE POLICY.</p>

        <p className={styles.ulHeader}>
          Genvite utilizes automatic data collection to improve our Services.
          The technologies we use for this automatic data collection may
          include:
        </p>

        <ul className={styles.bullets}>
          <li>To other users;</li>
          <li>
            Cookies (or browser cookies). A cookie is a small file placed on the
            hard drive of your computer. You may refuse to accept browser
            cookies by activating the appropriate setting on your browser.
            However, if you select this setting you may be unable to access
            certain parts of our Services. Unless you have adjusted your browser
            setting so that it will refuse cookies, our system will issue
            cookies when you direct your browser to our Services.
          </li>

          <li>
            Flash cookies. Certain features of our Services may use local stored
            objects (or Flash cookies) to collect and store information about
            your preferences and navigation to, from, and on our website. Flash
            cookies are not managed by the same browser settings as are used for
            browser cookies.
          </li>
          <li>
            Web Beacons. Pages of our website and our emails may contain small
            electronic files known as web beacons (also referred to as clear
            gifs, pixels, tags, and single-pixel gifs) that allow us to count
            users who have visited those pages or opened an email and for other
            related website statistics (for example, recording the popularity of
            certain website content and verifying system and server integrity).
          </li>
        </ul>

        <p className={styles.text}>
          Some cookies are necessary to allow you to browse and access parts of
          the Services. Other cookies, known as functional cookies, help the
          Services remember choices you have made or information you have
          provided such as your email address and password.
        </p>

        <p className={styles.text}>
          We may also use analytics cookies to understand your usage of the
          Services and our campaign effectiveness. The information collected and
          used in this manner is generally, on its own, anonymous and not
          personally identifiable.
        </p>

        <p className={styles.text}>
          We currently do not honor Do Not Track signals from internet browsers;
          when a universal standard for processing them emerges, we will follow
          it.
        </p>
        <p className={styles.text}>
          Note that third-party sites that are linked to the Services may set
          cookies or other technologies to collect information about your use
          and interaction with their websites or content. We do not have access
          to, or control, over such third-party data collection.
        </p>

        <p className={styles.title}>LINKS TO OTHER SITES.</p>
        <p className={styles.text}>
          The Services may contain links to other sites for your convenience
          only. If you click on a third- party link, you will be directed to
          that site. Note that these external sites are not operated by us.
          Therefore, we strongly advise you to review the applicable privacy
          policy of these third-party websites. We have no control over, and
          assume no responsibility for the content, privacy policies or
          practices of any third-party websites or services.
        </p>

        <p className={styles.title}>SECURITY AND RETENTION.</p>
        <p className={styles.text}>
          We have implemented commercially reasonable measures designed to
          secure your personal information from accidental loss and from
          unauthorized access, use, alteration, and disclosure.
        </p>
        <p className={styles.text}>
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Services, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone.
        </p>
        <p className={styles.text}>
          Unfortunately, the transmission of information via the Internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Services. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Services.
        </p>
        <p className={styles.text}>
          We will keep your personal information as long as reasonably necessary
          to fulfill the purposes for which it was collected. In some
          circumstances we may retain your personal information for longer if
          and when required (e.g., legal, tax, or accounting requirements).
        </p>
        <p className={styles.title}>CHILDREN.</p>
        <p className={styles.text}>
          Our Services not intended for children under sixteen (16) We do not
          knowingly collect or maintain personal information of individuals
          under sixteen (16) years old. If you believe that a child under the
          age of 16 has provided personal information to us through the
          Services, please contact us at info@genvite.com so we can use our best
          reasonable efforts to delete that information.
        </p>
        <p className={styles.title}>CONTACT INFORMATION.</p>
        <p className={styles.text}>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us by email at info@genvite.com.
        </p>
      </div>{" "}
    </div>
  );
};

export default PrivacyPopup;
