import { Link } from "react-router-dom";
import styles from "./TermsPopup.module.scss";

const TermsPopup = ({ data }) => {
  return (
    <div className={styles.popup}>
      <p className={`ffm fs32 fw700 c084571 ${styles.pageTitle}`}>
        GENVITE TERMS OF USE
      </p>
      <div className={styles.content}>
        <p
          className={`ffm fw6000 fs18 c3D4B5C`}
          style={{ marginBottom: "50px" }}
        >
          Last Updated: May 2023
        </p>
        <p className={styles.text}>
          The following terms of use (the “Terms”) apply to any user (“you,”
          “your,” or “User”) accessing or using the website, mobile websites,
          services, or software (collectively, the “Services”) provided by
          Genvite (“Genvite,” “we,” “us,” or “our”).
        </p>
        <p className={styles.text}>
          Please read these Terms carefully before you start to access or use
          the Services. By accessing and/or using the Services, you agree to be
          bound by these Terms and our Privacy Policy found{" "}
          <Link to="/privacy">here</Link> and incorporated herein by reference.
          We reserve the right to update or modify these Terms to keep current
          with upgrades to the Services and/or changes in applicable law. If you
          do not agree to these Terms or our Privacy Policy, you must not access
          or use the Services.
        </p>

        <p className={styles.title}>GENVITE IN GENERAL </p>

        <p className={styles.text}>
          Genvite does not own or create any communications or messages sent
          through our Services. Therefore, you are entirely responsible for any
          activity that occurs through your account. We expect our Users to use
          our Services in a respectful manner and consistent with these Terms.
          If a recipient indicates an opt-out or request not to be contacted
          (either through the Services or otherwise) you are expected to and
          fully responsible for honoring such request.
        </p>
        <p className={styles.text}>
          We may remove, edit, block, monitor, and/or terminate your access or
          use of the Services if we determine in our sole discretion, that your
          access or use violates these Terms.
        </p>

        <p className={styles.text}>
          You agree that you will not sell, transfer, license, disclose, or
          assign your account, username, password, or account rights to any
          third party. With the exception of people or businesses that are
          expressly authorized to create accounts on behalf of their employers
          or clients, you are prohibited from and agree not to create an account
          for anyone other than yourself. You represent that any and all
          information you provide through the Services is true, accurate,
          current, and complete.
        </p>
        <p className={styles.text}>
          You are responsible for keeping your login credentials secret and
          secure and will not solicit, collect, or use the login credentials of
          any other Genvite users. Your use of the Services to communicate with
          and/or send text messages or pre-recorded calls to invite individuals
          are solely between you and those individuals.
        </p>

        <p className={styles.text}>
          There may be links shared via the Services to third-party websites or
          features. We do not control any of these third-party websites,
          features, or any of their content and if you decide to use or access
          them you do so at your own risk and subject to the terms and
          conditions of such third- party. You expressly acknowledge and agree
          that Genvite is in no way responsible or liable for any such
          third-party materials. Your correspondence and dealings with
          third-parties found through the Services are solely between you and
          the third-party.
        </p>
            
        <p className={`${styles.text} ${styles.underlined}`}>
          A Tip Before Sending Invitations
        </p>
        <p className={styles.text}>
          At Genvite we want to make sending your invitations and reaching your invitees 
          a little bit easier. We also respect the privacy concerns around phone numbers 
          and ask that you do the same. Before sending invitations or sharing other individuals’ phone numbers and/or email addresses
          through our Services we strongly recommend that you separately ensure that person is OK receiving phone 
          calls, text messages or emails from you, and OK with you sharing their phone number and/or email address with us so they 
          won’t miss the next event. You represent and warrant that you have prior express permission from any 
          individuals whose phone numbers or email addresses you provide or use through the Services. You acknowledge and agree that 
          Genvite is not responsible or liable for any loss or damage of any sort incurred as a result of any communications, 
          email, text messages, or pre-recorded phone calls to phone numbers you provide through the Services. In addition 
          to the indemnification obligations expressed elsewhere in these Terms, you agree to indemnify, defend, and 
          hold harmless, Genvite from any and all third-party claims, actions, losses, or damages arising out of 
          or relating to the Services as a result of a phone number you provided through your use of the Services, 
          including, without limitation, any dispute between you and any other person, their attendance or failure 
          to attend an event, or rights under the TCPA or CAN-SPAM Act.
        </p>

        <p className={styles.title}>PRE-RECORDED CALLS &amp; MESSAGING TERMS</p>

        <p className={styles.text}>
          Genvite Services promote and allow users to share, organize, and
          access contact information for friends and family. The Services may be
          used to send automated and/or pre-recorded non-commercial
          announcements or invitations to these contacts for upcoming social
          events. By creating an Genvite account, you are opting-in for our email, text messaging and
          pre-recorded call notifications and you agree to these terms and
          conditions. Your consent is not a condition of any purchase.
        </p>

        <p className={styles.text}>
          By providing us with your email address, phone number and/or
          subscribing to Genvite notifications, you agree to receive email, text
          messages and pre-recorded calls through us about upcoming events.
          Message frequency is recurring. Message and data rates may apply.
        </p>
        <p className={styles.text}>
          You certify that any phone number(s) you provide is true and accurate.
          You agree to immediately notify us of any change in ownership of such
          phone number(s) you provided through the Services. If we discover that
          this information is false or inaccurate, we may suspend or terminate
          your account at any time.
        </p>
        <p className={styles.text}>
          We reserve the right to revise our messaging program at any time. Any
          changes will be effective immediately upon posting to this website.
          Your continued enrollment following such changes shall constitute your
          acceptance of such changes.
        </p>
        <p className={`${styles.text} ${styles.underlined}`}>
          Message and Data Rates May Apply for Recipients.
        </p>
        <p className={styles.text}>
          There is no fee to receive Genvite text notifications or calls,
          however, you may incur a charge for these messages from your telephone
          carrier which is your sole responsibility.
        </p>
        <p className={styles.text}>
          We reserve the right to alter the frequency of messages sent at any
          time and reserve the right to change the short code or phone number
          from which messages are sent.
        </p>
        <p className={styles.text}>
          Not all mobile devices or handsets may be supported and our messages
          may not be deliverable in all areas. Genvite, its service providers,
          and mobile carriers supported by this program are not liable for
          delayed or undelivered messages.
        </p>
        <p className={`${styles.text} ${styles.underlined}`}>Opt-Out Option.</p>
        <p className={styles.ulHeader}>
          To opt-out of receiving text messages, calls, and pre-recorded
          invitations, there are two (2) options:
        </p>

        <ul className={styles.numbers}>
          <li>
            <span className={`fw800`}>Account Holders:</span> You may indicate
            on your account that you do not wish to receive communications from
            us. If you choose to do so, we will honor your request and refrain
            from calling you. To indicate your preference, please log into your
            account and navigate to the Settings section – edit profile. From
            there, you can select the option to opt-out of communications.
          </li>
          <li>
            <span className={`fw800`}>Non-Account Holders:</span> Please send an
            email to unsubscribe@genvite.com with the words OPT-OUT or
            UNSUBSCRIBE. You understand and agree that attempting to opt- out by
            any means other than the opt-out process above is not a reasonable
            means of opting-out.
          </li>
        </ul>

        <p className={styles.text}>
          Please note that we may still contact you via other methods, such as
          email or text message, if you have not indicated a preference to
          opt-out of those methods. By using our service, you agree to comply
          with this provision and acknowledge that we will not be liable for any
          damages arising from our compliance with your opt-out request.
        </p>

        <p className={styles.title}>SERVICE FEES</p>

        <p className={styles.text}>
          To access and use the text and call features of the Services, you will
          be charged the applicable rate per call. International calls are
          currently not supported.
        </p>

        <p className={styles.text}>
          This includes any attempted text or call communications, including
          without limitation, (i) unsuccessfully texts or calls due to an
          incorrect number, or simply because the call or text did not go
          through; (ii) undelivered texts or failed calls; or (iii) if the
          recipient did not accept the call, has opted-out, or was reached in
          error.
        </p>

        <p className={styles.title}>INTELLECTUAL PROPERTY</p>
        <p className={styles.text}>
          All contents, features, and functionality (including but not limited
          to all information, software, text, trademarks, logos, displays,
          images, video, and audio, and the design, selection, and arrangement
          thereof) included in the Services are owned by Genvite, our licensors,
          or other providers of such material and are protected by United States
          and international copyright, trademark, patent, trade secret, and
          other intellectual property or proprietary rights laws. You may not
          copy, distribute, reproduce, mirror, frame, publicly display, publicly
          perform, translate, create derivative works, republish, or transmit
          the Services, in whole or in part, or in any way without our prior
          written consent or in accordance with a written agreement with
          Genvite.
        </p>

        <p className={styles.text}>
          If you print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of the Services in breach of
          these Terms, your right to access or use the Services will stop
          immediately and you must, at our option, return or destroy any copies
          of the materials you have made. No right, title, or interest in or to
          the Services is transferred to you, and all rights not expressly
          granted are reserved by Genvite.
        </p>
        <p className={styles.text}>
          You may not use any name, logo, tagline or other mark of Genvite or
          our licensors without our and any applicable licensor’s prior written
          consent.
        </p>

        <p className={styles.title}>ACCESS AND USE</p>
        <p className={styles.text}>
          You agree to comply with all applicable laws, rules, and regulations
          in connection with your access and use of the Services. You shall not
          use the Services for any illegal or unauthorized purpose.
        </p>

        <p className={styles.text}>
          You are solely responsible for any content, including without
          limitation, any data, text, files, information, including personal
          information, usernames, images, graphics, photos, profiles, audio,
          video clips, sounds, musical works, works of authorship, applications,
          links, and other content or materials, you send, transmit, display, or
          upload in connection with your access or use of the Services (“User
          Content”), including, but not limited to, any applicable laws
          requiring you to obtain the consent of a third-party to use the User
          Content and to provide appropriate notices of third-party rights. You
          represent and warrant that you have the right to upload the User
          Content to the Services and that such use does not violate or infringe
          on any rights of any third- party. Under no circumstances will Genvite
          be liable in any way for (a) User Content that is transmitted or
          viewed while using the Services; (b) errors or omissions in the User
          Content; or (c) any loss or damage of any kind incurred as a result of
          the use of, access to, or denial of access to User Content. Although
          Genvite is not responsible for any User Content, we may, in our sole
          discretion, refuse, modify, or delete any User Content, at any time
          without notice to you.
        </p>

        <p className={styles.ulHeader}>
          You agree that any User Content by you will not contain material that:
        </p>

        <ul className={styles.abc}>
          <li>
            is defamatory, obscene, indecent, abusive, offensive, harassing,
            violent, hateful, inflammatory, discriminatory or otherwise
            objectionable;
          </li>
          <li>is false, misleading or inaccurate;</li>
          <li>
            violates the legal rights (including the rights of publicity and
            privacy) of others or contains any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with these Terms and our
            Privacy Policy;
          </li>
          <li>poses a reasonable threat to personal or public safety;</li>
          <li>
            infringes any patent, trademark, trade secret, copyright, or other
            intellectual property or other rights of any other person;
          </li>
          <li>
            contains any advertising or promotional materials (e.g., spam, junk
            mail, or any other form of solicitation that is in violation of the
            TCPA, CAN-SPAM Act, CCPA, GDPR, or any other law; or
          </li>
          <li>contains any virus or other harmful component.</li>
        </ul>

        <p className={styles.text}>
          You grant Genvite a non-exclusive, fully paid and royalty-free,
          transferable, sub-licensable, worldwide license to use the User
          Content, including for commercial monetization. You represent and
          warrant that (a) you own your User Content or otherwise have the right
          to grant the rights and licenses set forth in these Terms; and (b) the
          posting and use of your User Content on or through the Services does
          not violate, misappropriate or infringe on the rights of any third
          party, including, without limitation, privacy rights, publicity
          rights, copyrights, trademark and/or other intellectual property
          rights.
        </p>
        <p className={styles.title}>CONFIDENTIALITY</p>
        <p className={styles.text}>
          As a user of our Services, you may have access to personal information
          belonging to other individuals. You agree to keep all such information
          confidential and not to disclose it to any third party unless you have
          obtained the express consent of the individual concerned. You
          acknowledge that any unauthorized use or disclosure of personal
          information may result in legal action being taken against you. By
          using our service, you agree to comply with all applicable privacy
          laws and regulations, and to respect the privacy rights of others.
        </p>
        <p className={styles.text}>
          For more information regarding Genvite’s handling of, and policies
          for, personal information, please see our{" "}
          <Link to="/privacy">Privacy Policy.</Link>
        </p>

        <p className={styles.title}>RESTRICTIONS ON USE</p>
        <p className={styles.ulHeader}>
          You agree not to access or use the Services for any purpose that is
          unlawful or prohibited by these Terms, including without limitation,
          to:
        </p>
        <ul className={styles.abc}>
          <li>
            transmit any information or message that contains unlawful,
            infringing, threatening, fraudulent, libelous, defamatory, obscene
            or abusive information or language;
          </li>
          <li>
            falsify or delete any author attributions, legal or other proper
            notices or proprietary designations or labels of the origin or
            source of software or other material contained in a file that is
            uploaded;
          </li>
          <li>
            impersonate any person or entity without proper authorization from
            that person or entity;
          </li>
          <li>
            falsely state or misrepresent your affiliation with any person or
            entity, including the origin of any information you provide;
          </li>
          <li>
            reproduce, duplicate, copy, download, store, further transmit,
            disseminate, distribute, transfer, or otherwise exploit the
            Services, or any portion thereof without the prior written consent
            of Genvite, except that content on the Services may be viewed,
            reproduced, and downloaded solely for your own authorized use as a
            paying customer and is not in use or shared in any way that is
            commercially prejudicial to or competitive with Genvite;
          </li>
          <li>
            use any device, software, or routine to interfere or attempt to
            interfere with the proper working of the Services, or attempt to
            probe, scan, test the vulnerability of, or breach the security of
            any Genvite system or network;
          </li>
          <li>
            interfere with or attempt to interfere with any software making up a
            part of the Services, by way of circumvention, reverse engineering,
            deciphering, decompiling, disassembling, decrypting, or otherwise;
          </li>
          <li>
            introduce any viruses, trojan horses, worms, logic bombs, or other
            material which is malicious or technically harmful;
          </li>
          <li>
            use any robot, spider, intelligent agent, other automatic device or
            manual process to search, monitor, or copy the Website, or the
            reports, data, information, content, software, products, or other
            materials on, generated by or obtained from the Services or any
            other unauthorized automated means to compile information without
            Genvite’s permission, with the exception of generally available
            third-party web browsers;
          </li>
          <li>
            obtain or attempt to gain unauthorized access to other computer
            systems, materials, information, or any services available on or
            through the Services;
          </li>
          <li>
            {" "}
            use the Services in any manner that could damage or overburden any
            Genvite server, or any network connected to any Genvite server, as
            all servers have limited capacity and are used by many people;
          </li>
          <li>
            upload or transmit any communication, software, or material that
            contains a virus or is otherwise harmful to Genvite or our users’
            computers and/or systems;
          </li>
          <li>
            engage in any other conduct that restricts or inhibits any person
            from using or enjoying the Website, or that in our sole discretion,
            exposes us or any of our users, employees, affiliates, or any other
            third-party to any liability, damage, or detriment of any type;
          </li>
          <li>
            advertise, offer to sell or buy any goods or services for any
            business purpose, including any “junk mail,” “chain letter,” “spam,”
            or any other similar solicitation; or
          </li>
          <li>violate any applicable laws or regulations.</li>
        </ul>
        <p className={styles.text}>
          Violation of system or network security and certain other conduct may
          result in civil or criminal liability. We may investigate and work
          with law enforcement authorities to prosecute users who violate these
          Terms. We may suspend or terminate your access to the Services for any
          or no reason at any time without notice.
        </p>
        <p className={styles.title}>CHILDREN</p>
        <p className={styles.text}>
          The Services are not intended for anyone under the age of 16. If you
          are under the age of 16 you may only use the Services with the consent
          of your parent or legal guardian.
        </p>
        <p className={styles.title}>DISCLAIMER</p>
        <p className={styles.text}>
          THE SERVICES ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE” AND “WITH ALL
          FAULTS” BASIS. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, NEITHER
          GENVITE NOR ITS PARENT COMPANIES, AFFILIATES, NOR ANY OF THEIR
          EMPLOYEES, MANAGERS, OFFICERS, OR AGENTS (COLLECTIVELY, THE “GENVITE
          PARTIES”) MAKE ANY REPRESENTATIONS OR WARRANTIES OR ENDORSEMENTS OF
          ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, AS TO: (A) THE SERVICES; (B)
          USER CONTENT; OR (C) SECURITY ASSOCIATED WITH THE TRANSMISSION OF
          INFORMATION TO GENVITE OR VIA THE SERVICES. IN ADDITION, THE GENVITE
          PARTIES DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET
          ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUSES. THE
          GENVITE PARTIES DO NOT REPRESENT OR WARRANT THAT THE SERVICES WILL BE
          ERROR-FREE OR UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; OR THAT
          THE SERVICES OR THE SERVER THAT MAKES THE SERVICES AVAILABLE IS FREE
          FROM ANY HARMFUL COMPONENTS, INCLUDING, WITHOUT LIMITATION, VIRUSES.
          THE GENVITE PARTIES DO NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT
          THE INFORMATION (INCLUDING ANY INSTRUCTIONS) ON THE SERVICES ARE
          ACCURATE, COMPLETE, OR USEFUL AND YOU ACKNOWLEDGE THAT YOUR ACCESS
          AND/OR USE OF THE SERVICES IS AT YOUR OWN SOLE RISK. THE GENVITE
          PARTIES DO NOT ENDORSE CONTENT AND SPECIFICALLY DISCLAIM ANY
          RESPONSIBILITY OR LIABILITY TO ANY PERSON OR ENTITY FOR ANY LOSS,
          DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE, OR OTHERWISE),
          INJURY, CLAIM, LIABILITY, OR OTHER CAUSE OF ANY KIND OR CHARACTER
          BASED UPON OR RESULTING FROM ANY SERVICES OR CONTENT.
        </p>
        <p className={styles.title}>INDEMNIFICATION</p>
        <p className={styles.text}>
          You (and any third-party for whom you operate an account or activity
          through the Services) agree to defend, indemnify, and hold harmless,
          the Genvite Parties from and against any and all claims, liabilities,
          damages, losses, and expenses, including without limitation,
          reasonable attorneys’ fees and costs, arising out of or in any way
          connected with any of the following: (a) your User Content; (b) your
          access to or use of the Services; (c) your breach or alleged breach of
          these Terms; (d) your violation of any third-party rights of
          intellectual property, publicity, confidentiality, or privacy; (e)
          your violation of any applicable law; (f) your gross negligence or
          willful misconduct; or (g) any misrepresentation made by you.
        </p>
        <p className={styles.title}>LIMITATION OF LIABILITY</p>
        <p className={styles.text}>
          UNDER NO CIRCUMSTANCES WILL THE GENVITE PARTIES BE LIABLE TO YOU FOR
          ANY LOSS OR DAMAGE OF ANY KIND (INCLUDING, WITHOUT LIMITATION, FOR ANY
          DIRECT, INDIRECT, ECONOMIC, EXEMPLARY, SPECIAL, PUNITIVE, INCIDENTAL,
          OR CONSEQUENTIAL LOSSES OR DAMAGES) THAT ARE DIRECTLY OR INDIRECTLY
          RELATED TO (A) THE SERVICES; (B) USER CONTENT; (C) YOUR ACCESS OR USE,
          INABILITY TO ACCESS OR USE, THE SERVICES OR THE PERFORMANCE OF THE
          SERVICES; (D) ANY ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY
          THE GENVITE PARTIES OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR OR
          ANY OTHER PARTY’S USE OF THE SERVICES; (E) ANY ACTION TAKEN IN
          CONNECTION WITH COPYRIGHT OR OTHER INTELLECTUAL PROPERTY OWNERS; (F)
          ANY ERRORS OR OMISSIONS IN THE SERVICES’ OPERATION; OR (G) ANY DAMAGE
          TO YOUR OR ANY USER’S COMPUTER, MOBILE DEVICE, OR OTHER EQUIPMENT OR
          TECHNOLOGY INCLUDING, WITHOUT LIMITATION, DAMAGES FROM ANY SECURITY
          BREACH OR FROM ANY VIRUS, BUGS, TAMPERING, FRAUD, ERROR, OMISSION,
          INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER
          LINE OR NETWORK FAILURE OR ANY OTHER TECHNICAL OR OTHER MALFUNCTION,
          INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOSS OF
          GOODWILL, LOSS OF DATA, WORK STOPPAGE, ACCURACY OF RESULTS, OR
          COMPUTER FAILURE OR MALFUNCTION, EVEN IF FORESEEABLE OR EVEN IF THE
          GENVITE PARTIES HAVE BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE
          POSSIBILITY OF SUCH DAMAGES, WHETHER IN ACTION OF CONTRACT,
          NEGLIGENCE, STRICT LIABILITY OR TORT (INCLUDING WITHOUT LIMITATION,
          WHETHER CAUSED IN WHOLE OR IN PART BY THE NEGLIGENCE OF THE GENVITE
          PARTIES, ACTS OF GOD, TELECOMMUNICATIONS FAILURE, OR THEFT OR
          DESTRUCTION OF THE SERVICES). IN NO EVENT WILL GENVITE BE LIABLE TO
          YOU OR ANYONE ELSE FOR LOSS, DAMAGE, OR INJURY, INCLUDING, WITHOUT
          LIMITATION, DEATH OR PERSONAL INJURY. IN NO EVENT WILL THE GENVITE
          PARTIES TOTAL LIABILITY TO YOU EXCEED ONE HUNDRED DOLLARS ($100.00).
        </p>
        <p className={styles.title}>MISCELLANEOUS</p>
        <p className={styles.text}>
          These Terms are governed by and construed in accordance with the laws
          of the State of New York, without giving effect to any principles of
          conflicts of law. You agree to resolve any dispute arising out or
          relating to your access and/or use of the Services in a state or
          federal court located in New York County, New York.
        </p>
        <p className={styles.text}>
          No waiver by Genvite of any term or condition set out in these Terms
          shall be deemed a further or continuing waiver of such term or
          condition or a waiver of any other term or condition, and any failure
          of Genvite to assert a right or provision under these Terms shall not
          constitute a waiver of such right or provision.
        </p>
        <p className={styles.text}>
          If any provision of these Terms is held by a court or other tribunal
          of competent jurisdiction to be invalid, illegal, or unenforceable for
          any reason, such provision shall be eliminated or limited to the
          minimum extent such that the remaining provisions of the Terms will
          continue in full force and effect.
        </p>
        <p className={styles.text}>
          These Terms constitute the entire agreement between you and Genvite
          with respect to the subject matter hereof and supersedes all prior and
          contemporaneous understandings, agreements, representations, and
          warranties, both written and oral, with respect to such subject
          matter.
        </p>
      </div>
    </div>
  );
};

export default TermsPopup;
